import React from "react";
import { connect } from "react-redux";

import { authUserSet, authTokenSet, authEmployeeSet } from "../../redux";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.authUserSet(JSON.parse(localStorage.getItem("authUser")));
      this.props.authTokenSet(JSON.parse(localStorage.getItem("authToken")));
      this.props.authEmployeeSet(JSON.parse(localStorage.getItem("authEmployee")));

    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    authEmployeeSet: (authEmployeeData) => dispatch(authEmployeeSet(authEmployeeData))
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
