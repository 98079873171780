import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import AddCookModal from "./AddCookModal";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import beeicon from "../../assets/bee_icon.svg";

function SideNavItem({ children, to, activeOnlyWhenExact, secondLevel }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <li className={match ? "side-nav-item mm-active" : "side-nav-item"}>
      <Link
        className={match ? "side-nav-link active" : "side-nav-link"}
        to={to}
      >
        {children}
      </Link>
      {secondLevel}
    </li>
  );
}

const Sidebar = ({ authUser, authEmployee }) => {
  const [showAddCookModal, setShowAddCookModal] = useState(false);

  return (
    <div>
      {authUser && (
        <div className="left-side-menu left-side-menu-detached mm-active">
          <div
            className="leftbar-user"
            style={{
              backgroundImage: `url('/assets/images/waves.png')`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <Link to="/">
              <img
                src={
                authUser.image
                  ? authUser.image
                  : "https://coox-new.s3.ap-south-1.amazonaws.com/images/default/cook/cook_img.jpg"
                  }
                alt="user"
                height="42"
                className="rounded-circle shadow-sm"
              />
              <span className="leftbar-user-name">
                {authUser.name || authUser.mobile}
              </span>
              <span className="leftbar-user-name font-weight-light">
                ({authUser.roles[0]})
              </span>
            </Link>
          </div>

          {/* <!--- Sidemenu --> */}
          <ul className="metismenu side-nav m-show ">
            {/* {authUser.roles.includes(ROLES.SUPERADMIN) ? (
              <li className="side-nav-title side-nav-item">FOR SUPERADMIN</li>
            ) : null} */}
            {!(
              authUser.roles.includes(ROLES.MEMBER) ||
              authUser.roles.includes(ROLES.HR)
            ) && (
              <SideNavItem to={ROUTES.BOOKINGSDASHBOARD}>
                <i className="dripicons-cutlery"></i>
                <span> Bookings </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.ADMIN)) && (
              <SideNavItem to={ROUTES.SALESDASHBOARD}>
                <i className="fa-solid fa-dollar"></i>
                <span> Sales </span>
              </SideNavItem>
            )}

            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.FINANCE)
            ) && (
              <SideNavItem to={ROUTES.COINDASHBOARD}>
                <i className="mdi mdi-coins"></i>
                <span>Wallet</span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.FINANCE)
            ) && (
              <>
                <SideNavItem to={ROUTES.SUPPLYDASHBOARD}>
                  <i className="dripicons-monitor"></i>
                  {/* <span className='badge badge-info badge-pill float-right'>4</span> */}
                  <span> Supply </span>
                </SideNavItem>
                {/* <SideNavItem to={ROUTES.FINDCOOK}>
                  <i className="dripicons-search"></i>
                  
                  <span> Find Partner </span>
                </SideNavItem> */}
                {/* <li className="side-nav-title side-nav-item">Actions</li> */}
              </>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN)
            ) && (
              <>
                <SideNavItem to={ROUTES.ASSIGNEDCOOKS}>
                  <i className="mdi mdi-bookmark-outline"></i>
                  <span> Assigned Partners </span>
                </SideNavItem>
                {/* <li className="side-nav-title side-nav-item">Actions</li> */}
              </>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.MEMBER) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.JRADMIN) || 
              authUser.roles.includes(ROLES.FINANCE)
            ) && (
              <>
                <AddCookModal
                  currentUserMember={authUser.roles.includes(ROLES.MEMBER)}
                  showAddCookModal={showAddCookModal}
                  setShowAddCookModal={setShowAddCookModal}
                  roles={authUser.roles}
                />
                <li className="side-nav-item">
                  <Link
                    className="side-nav-link"
                    to="#!"
                    onClick={() => {
                      setShowAddCookModal(true);
                    }}
                  >
                    <i className="dripicons-plus"></i>

                    <span> Add Partner </span>
                  </Link>
                </li>
              </>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.HELPERSDASHBOARD}>
                <i className=" dripicons-user"></i>
                <span> Helper </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.PENALTIESDASHBOARD}>
                <i className="fa-solid fa-hammer"></i>
                <span> Penalties </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.KITSDASHBOARD}>
                <i className="dripicons-suitcase"></i>
                <span> Kits </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.DIGITALMARKETER)) && (
              <>
                <SideNavItem to={ROUTES.DISHDASHBOARD}>
                  <i className="mdi mdi-food"></i>
                  <span> Dishes </span>
                </SideNavItem>
              </>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.MARKETER)
              ) && (
                <SideNavItem
                  to={ROUTES.INGREDIENTSDASHBOARD}
                  // secondLevel={
                  //   <ul
                  //     className="side-nav-second-level mm-collapse"
                  //     aria-expanded="false"
                  //   >
                  //     <li>
                  //       <Link to={ROUTES.BRANDSDASHBOARD}>Brands</Link>
                  //     </li>
                  //     <li>
                  //       <Link to={ROUTES.SHOPSDASHBOARD}>Shops </Link>
                  //     </li>
                  //   </ul>
                  // }
                >
                  <i className="mdi mdi-fruit-cherries"></i>
                  <span> Ingredients </span>
                </SideNavItem>
              )}
              {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN)
              ) && (
                <>
                <SideNavItem to={ROUTES.BRANDSDASHBOARD}>
                  <i className="mdi mdi-tag-outline"></i>
                  <span>Brands </span>
                </SideNavItem>
                <SideNavItem to={ROUTES.SHOPSDASHBOARD}>
                  <i className="mdi mdi-store"></i>
                  <span>Shops</span>
                </SideNavItem>
              </>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.MARKETER)
              ) && (
              <>
                <SideNavItem to={ROUTES.APPLIANCESDASHBOARD}>
                  <i className="mdi mdi-grill-outline"></i>
                  <span> Appliances </span>
                </SideNavItem>
              </>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.DIGITALMARKETER) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.CAKESDASHBOARD}>
                <i className="fa-solid fa-cake-candles"></i>
                <span> Cakes </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.DIGITALMARKETER) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.DECORATIONSDASHBOARD}>
                <i className="fa-solid fa-gift"></i>
                <span> Decorations </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.HOTELSDASHBOARD}>
                <i className="fa-solid fa-hotel"></i>
                <span> Hotels </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.MARKETER)) && (
              <SideNavItem to={ROUTES.COUPONSDASHBOARD}>
                <i className="fa-solid fa-money-bill"></i>
                <span> Coupons </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.DIGITALMARKETER) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <>
                <SideNavItem to={ROUTES.PICTURESDASHBOARD}>
                  <i className="dripicons-photo"></i>

                  <span> Pictures </span>
                </SideNavItem>
              </>
            )}
            {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.MARKETER)) && (
              <>
                <SideNavItem to={ROUTES.CUISINESANDCOMBOSDASHBOARD}>
                  <i className="fa-solid fa-walkie-talkie"></i>
                  <span> Controller </span>
                </SideNavItem>
              </>
            )}
            {authUser.roles.includes(ROLES.DIGITALMARKETER) && (
              <>
                <SideNavItem to={ROUTES.CUISINESANDCOMBOSDASHBOARD}>
                  <i className="fa-solid fa-walkie-talkie"></i>

                  <span> Controller </span>
                </SideNavItem>
              </>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <SideNavItem to={ROUTES.CONTACTDASHBOARD}>
                <i className=" dripicons-device-mobile"></i>
                <span> Contact </span>
              </SideNavItem>
            )}
            {
              // authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) && (
                // authUser.roles.includes(ROLES.BASIC))
                <SideNavItem to={ROUTES.LOGINSDASHBOARD}>
                  <i className="uil-align-center-h"></i>
                  <span> Logins </span>
                </SideNavItem>
              )
            }
            {((authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.DIGITALMARKETER) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.MEMBER) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.HR) || 
              authUser.roles.includes(ROLES.FINANCE)
            ))&& (
              <SideNavItem to={ROUTES.HOME}>
                <i className="uil-home-alt"></i>
                {/* <span className='badge badge-info badge-pill float-right'>4</span> */}
                <span> Home </span>
              </SideNavItem>
            )}

            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.HR)) && (
              <>
                {" "}
                <SideNavItem to={ROUTES.USERACCESS}>
                  <i className="mdi mdi-account-key-outline"></i>
                  <span> User Access </span>
                </SideNavItem>
              </>
            )}
            {/* {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.MARKETER)) && (
              <>
                <SideNavItem to={ROUTES.CUISINESANDCOMBOSDASHBOARD}>
                  <i className="mdi mdi-food-fork-drink"></i>
                  <span> Cuisines & Combos </span>
                </SideNavItem>
              </>
            )} */}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.MARKETER)) && (
              <SideNavItem to={ROUTES.HOWZZAT}>
                <i className=" dripicons-article"></i>
                <span> Howzzat </span>
              </SideNavItem>
            )}
            {(authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.MARKETER) ||
              authUser.roles.includes(ROLES.SENIORMARKETER) ||
              authUser.roles.includes(ROLES.DIGITALMARKETER) ||
              authUser.roles.includes(ROLES.JRADMIN) ||
              authUser.roles.includes(ROLES.MEMBER) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.HR) ||
              authUser.roles.includes(ROLES.FINANCE)
            ) &&
              !authEmployee?.is_company_number && (
                <SideNavItem to={ROUTES.BEEHIVE}>
                  <img
                    src={beeicon}
                    alt="Bee Icon"
                    style={{ height: "25px" }}
                  />
                  <span className="ml-1"> BeeHive </span>
                </SideNavItem>
              )}
            {/* {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.BASIC) ||
              authUser.roles.includes(ROLES.JRADMIN)) && (
              <li className="side-nav-title side-nav-item">Navigation</li>
            )} */}

            {/* {(authUser.roles.includes(ROLES.ADMIN) ||
              authUser.roles.includes(ROLES.SUPERADMIN) ||
              authUser.roles.includes(ROLES.JRADMIN)||
              authUser.roles.includes(ROLES.MARKETER)) && (
              <>
                <SideNavItem to={ROUTES.BOOKINGCALENDAR}>
                  <i className="dripicons-calendar"></i>
                  <span> Booking Calendar </span>
                </SideNavItem>
                </>)} */}
            {(authUser.roles.includes(ROLES.SUPERADMIN) || authUser.roles.includes(ROLES.FINANCE)) && (
              <>
                <SideNavItem to={ROUTES.STATS}>
                  <i className="fa fa-chart-bar"></i>
                  <span> Stats</span>
                </SideNavItem>
              </>
            )}
              {authUser.roles.includes(ROLES.SUPERADMIN) && (
              <>
                <SideNavItem to={ROUTES.PARTNERTEST}>
                  <i className="fas fa-edit"></i>
                  <span> Partner Test</span>
                </SideNavItem>
              </>
            )}
          </ul>

          {/* <!-- End Sidebar --> */}

          <div className="clearfix"></div>
          {/* <!-- Sidebar -left --> */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.login.authUser,
    authEmployee: state.login.authEmployee,
  };
};

export default connect(mapStateToProps)(Sidebar);
